import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaTag = () => {
  // First Schema: LocalBusiness
  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Digital Marketing Service Agency",
    "image": "",
    "@id": "https://www.webwisedigital.in/services",
    "url": "https://www.webwisedigital.in/",
    "telephone": "099537 75715",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1016/2, Fourth Floor, Beegreen plaza, Mahipalpur",
      "addressLocality": "New Delhi, Delhi",
      "postalCode": "110037",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 28.5483223,
      "longitude": 77.13307139999999
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "10:00",
      "closes": "19:00"
    },
    "sameAs": [
      "https://www.facebook.com/people/WebWise-Digital-Pvt-Ltd/61552903648554/",
      "https://www.instagram.com/webwisedigitalpvtltd/",
      "https://www.linkedin.com/company/webwise-digital-pvt-ltd",
      "https://www.webwisedigital.in/",
      "https://twitter.com/WebwiseD"
    ]
  };

  // Second Schema: WebSite
  const websiteSchema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Webwisedigital",
    "url": "https://www.webwisedigital.in/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(localBusinessSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
    </Helmet>
  );
};

export default SchemaTag;
